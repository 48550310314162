

































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class FoulingHelp extends Vue {
  tab = null;
}
